@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Handwriting";
  src: local("Handwriting"), url(./assets/Handwriting-Regular.ttf) format("ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Inter&family=Roboto&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Archivo&family=Lato&family=Vollkorn&display=swap");
